<!-- 专区 -->
<template>
    <div v-if="plate && goodList.length>0" class="special-zone-container">
        <div class="special-zone-title flex justify-s-b align-c">
            <span class="font-24">{{plate.advname}}</span>
            <div class="color-666 point" @click="toGoodList">
                <span>查看更多</span>
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
        <div class="flex flex-wrap mt-20">
            <div class="good-item" v-for="(item, index) in goodList" :key="index">
                <GoodItem :goods="item"/>
            </div>
        </div>
    </div>
</template>

<script>
import GoodItem from '@/components/goodItem'

export default {
    name: 'SpecialZone',
    components: { GoodItem },
    props: {
        type: Number
    },

    data() {
        return {
            plate: null,
            goodList: []
        };
    },

    created() {
        this.getTitle()
    },

    methods: {
        getTitle() {
            this.$https.get(this.$api.banner,{
                type: this.type,
                mid: this.$store.state.userInfo.id,
                centerId: this.$store.state.userInfo.centreId,
                top: 10
            })
            .then((res) =>{
                if(res.data.length > 0){
                    this.plate = res.data[0]
                }
                else {
                    this.plate = null
                    this.goodList = []
                    return
                }
                this.getGoods()
            })
        },

        getGoods() {
            let userInfo = this.$store.state.userInfo
            this.$https.get(this.$api.products,{
                centreId: userInfo.centreId,
                liansuoid: userInfo.upshopTid,
                teamId: userInfo.upshopTid,
                mid: userInfo.id,
                isTeam: '',
                title: '',
                isKuaidi: '',
                categoryId: this.type == 13 ? this.plate.link.split('=')[1] : '',//分类id
                isSpellgroup: '',//拼团商品
                ismiaosha: '',
                ishot: '',
                isvip: '',
                productType: this.type == 13 ? 0 : this.type == 3 ? 3 : '',//0:普通 1预售 2拼团 3秒杀 4预约 5积分兑换 6砍价 10会员商品
                control: this.type == 16 ? 1 : '',
                medicine: this.type == 17 ? 1 : '', //是否是基药专区
                designatedArea: this.type == 18 ? 1 : '', //是否指定专区
                offset: 0,
                limit: 5
            })
            .then((res) =>{
                this.goodList = res.data.rows
            })
        },

        toGoodList() {
            let query = {
                name: encodeURIComponent(this.plate.advname)
            }
            if(this.type == 13) {
                query.categoryId = this.plate.link.split('=')[1]
                query.productType = 0
            } else if(this.type == 3) {
                query.productType = 3
            } else if(this.type == 16) {
                query.control = 1
            } else if(this.type == 17) {
                query.medicine = 1
            } else if(this.type == 18) {
                query.designatedArea = 1
            }
			this.$router.push({
				name: 'goodList',
				query: query
			})
		}
    }
}
</script>
<style lang='scss' scoped>
    .special-zone-container{
        margin-top: 15px;
    }
    .special-zone-title{
        padding: 10px 0;
        border-bottom: 1px solid #DEDEDE;
    }
    .good-item{
        margin:0 12.5px 12.5px 0;
        &:nth-child(5n){
            margin-right: 0;
        }
    }
</style>